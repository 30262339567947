<template>
  <div class="main-body">
    <div class="card-item">
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
        <div class="order-attention">
          <p>微信公众号设置</p>
        </div>

        <a-form-item label="公众号名称">
          <a-input v-model:value="formState.wechat_name" placeholder="请输入公众号名称" />
        </a-form-item>
        <a-form-item label="原始ID">
          <a-input v-model:value="formState.wechat_original" placeholder="请输入原始ID" />
        </a-form-item>
        <a-form-item label="公众号二维码">
          <CsImage v-model:imgOne="formState.qrcode" />
        </a-form-item>
        <div class="order-attention">
          <p>开发设置</p>
        </div>
        <a-form-item label="APPID">
          <a-input v-model:value="formState.app_id" placeholder="请输入APPID" />
        </a-form-item>
        <a-form-item label="APP密钥">
          <a-input v-model:value="formState.app_secret" placeholder="请输入APP密钥" />
        </a-form-item>
        <a-form-item label="业务域名校验文件" extra="仅支持上传TXT格式的文件">
          <UpFile v-model:imgOne="formState.check_file" />
        </a-form-item>

        <div class="order-attention">
          <p>公众平台通信</p>
        </div>
        <a-form-item label="Token" extra="Token必须为英文或数字，长度为3-32字符。如不填写则默认为“TOKEN”。">
          <a-input v-model:value="formState.token" placeholder="请输入Token" />
        </a-form-item>
        <a-form-item label="URL">
          <a-input v-model:value="formState.url" placeholder="请输入URL" />
        </a-form-item>
        <a-form-item label="EncodingAESKey">
          <a-input v-model:value="formState.encoding_aes_key" placeholder="请输入EncodingAESKey" />
        </a-form-item>
        <div class="order-attention">
          <p>配置说明</p>
        </div>
        <a-form-item label="业务域名" extra="设置业务域名（用户在相应域名上进行输入时不再出现防欺诈盗号等安全提示）">
          <a-input v-model:value="formState.public_url" placeholder="请输入业务域名" />
        </a-form-item>
        <a-form-item label="授权域名" extra="填写授权回调页面域名业务域名设置完毕！">
          <a-input v-model:value="formState.auth_url" placeholder="请输入授权域名" />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 6, offset: 2 }">
          <a-button type="primary" @click="onSubmit">保存</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, onMounted } from "vue";
import CsImage from "@/components/UpImage";
import UpFile from "@/components/UpFile";
import { useRoute } from "vue-router";
import * as Api from "@/views/client/api";
import { message } from "ant-design-vue";
export default defineComponent({
  components: {
    CsImage,
    UpFile,
  },
  setup() {
    //获取当前应用ID
    const route = useRoute();
    const appid = route.query.appid;

    const formState = reactive({
      appid: appid,
      wechat_name: "",
      wechat_original: "",
      qrcode: "",
      app_id: "",
      app_secret: "",
      check_file: "",
      token: "",
      url: "",
      encoding_aes_key: "",
      public_url: "",
      auth_url: "",
    });

    const onSubmit = () => {
      Api.client_save_config(formState).then((res) => {
        message.success(res.message);
      });
    };

    const getInitConfig = () => {
      Api.client_get_config({ appid: appid }).then((res) => {
        formState.wechat_name = res.data.setting.wechat_name
          ? res.data.setting.wechat_name
          : "";
        formState.wechat_original = res.data.setting.wechat_original
          ? res.data.setting.wechat_original
          : "";
        formState.qrcode = res.data.setting.qrcode
          ? res.data.setting.qrcode
          : "";
        formState.app_id = res.data.setting.app_id
          ? res.data.setting.app_id
          : "";
        formState.app_secret = res.data.setting.app_secret
          ? res.data.setting.app_secret
          : "";
        formState.check_file = res.data.setting.check_file
          ? res.data.setting.check_file
          : "";
        formState.token = res.data.setting.token ? res.data.setting.token : "";
        formState.url = res.data.setting.url ? res.data.setting.url : "";
        formState.encoding_aes_key = res.data.setting.encoding_aes_key
          ? res.data.setting.encoding_aes_key
          : "";
        formState.public_url = res.data.setting.public_url
          ? res.data.setting.public_url
          : "";
        formState.auth_url = res.data.setting.auth_url
          ? res.data.setting.auth_url
          : "";
      });
    };

    onMounted(() => {
      getInitConfig();
    });

    return {
      formState,
      onSubmit,
      labelCol: {
        span: 2,
      },
      wrapperCol: {
        span: 6,
      },
    };
  },
});
</script>
<style scoped lang="less">
.card-item {
  margin-bottom: 50px;
  .action-body {
    display: flex;
    flex-wrap: wrap;
    .action-item {
      width: 20%;
      .action-item-btn {
        display: flex;
        background-color: #f0f2f5;
        margin: 20px;
        padding: 10px;
        border-radius: 3px;
        cursor: pointer;
        .action-icon {
          font-size: 36px;
          margin: 6px 10px;
          color: #52c41a;
        }
        .title {
          font-size: 14px;
        }
        .desc {
          color: #999999;
        }
      }
    }
  }
  .weixin-url {
    margin: 20px 0;
  }
  .action-time {
    margin-left: 20px;
  }
}
</style>